import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import CaseStudyRoll from "../components/CaseStudyRoll";
import Collaborator from "../components/Collaborator";
// import Testimonials from "../components/Testimonials";

export default class CaseStudyPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Case Studies | CVision AI</title>
        </Helmet>
        <div className="hero bg-indigo-800">
          <h1 className="font-serif pb-40 md:pb-64 pt-6 md:pt-20 md:text-7xl text-4xl text-center">
            Case Studies
          </h1>
        </div>
        <div className="bg-gray-100 pb-56">
          <div className="max-w-screen-xl md:pb-40 pb-16 pt-32 mx-auto px-6">
            <div className="-mt-64">
              <CaseStudyRoll relatedCaseStudy={null} startPos={0} limit={1000} />
            </div>
            <Collaborator />
          </div>
        </div>
      </Layout>
    );
  }
}
